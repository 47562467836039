import { useState } from 'react';
import { Stack } from '@mui/material';
import { useSiteTemplates, useDeleteSiteTemplate } from 'api/site';
import { ConfirmationDialog } from '../base/dialogs/ConfirmationDialog';
import { Trans, useTranslation } from 'react-i18next';
import { Table } from 'component/new_design/base/Table';
import { TableColumnType, TableRowActionType } from 'component/new_design/base/Table/types';
import { usePaginatedListState } from 'component/hooks/usePaginatedListState';
import { EmptyState } from '../base/EmptyState';
import CreateSiteTemplate from './CreateSiteTemplate';
import { formatLocaleDate } from 'utils/dateFormat';

// icons
import EyeOn from '../icons/EyeOn.svg?react';
import Monitor from '../icons/Monitor.svg?react';
import Plus from '../icons/plus.svg?react';
import DeleteDustbin from '../icons/DeleteDustbin.svg?react';
import { useSnackbar } from 'component/hooks/useSnackbar';
import { components } from 'openapi-types';
import { useNavigate } from 'react-router-dom';
import { linkHelper } from 'linkHelper';
import { useRoleGuard } from 'component/hooks/useRoleGuard';
import { RoleGuard } from 'component/base/RoleGuard';
import { CreateSiteModal } from './CreateSite/CreateSiteModal';

type SiteTemplate = components['schemas']['SiteTemplate'];

function VersionCell({ template }: { readonly template: SiteTemplate }) {
  return template.config?.wordpress?.version ?? '-';
}

function CreatedFromCell({ template }: { readonly template: SiteTemplate }) {
  return template.created_from?.domain ?? '-';
}

export default function SiteTemplateList() {
  const [createSiteFromTemplate, setCreateSiteFromTemplate] = useState<string | null>(null);
  const [templateToDelete, setTemplateToDelete] = useState<SiteTemplate | null>(null);
  const tableState = usePaginatedListState({ urlStatePrefix: 'siteTemplates' });
  const { isLoading, data } = useSiteTemplates(tableState);
  const { mutateAsync: deleteTemplate } = useDeleteSiteTemplate();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const enableActions = !useRoleGuard(['billing_admin'], 'block');

  const { t, i18n } = useTranslation();

  const columns: TableColumnType<SiteTemplate>[] = [
    {
      label: t('template_name'),
      key: 'name',
      sortable: true,
    },
    {
      label: t('created'),
      key: 'created_at',
      renderValue: template =>
        formatLocaleDate({ locale: i18n.language, value: template.created_at }),
      sortable: true,
    },
    {
      label: t('created_from'),
      key: 'created_from',
      renderValue: template => <CreatedFromCell template={template} />,
      sortable: false,
    },
    {
      label: t('wp_version'),
      key: 'config',
      renderValue: template => <VersionCell template={template} />,
      sortable: false,
    },
  ];

  const rowActions: TableRowActionType<SiteTemplate>[] = enableActions
    ? [
        {
          onClick: siteTemplate => {
            setCreateSiteFromTemplate(siteTemplate.template_id ?? '');
          },
          icon: <Plus />,
          inline: true,
          label: t('create_site'),
        },
        {
          onClick: siteTemplate => {
            navigate(
              linkHelper.newDesign.settings.siteTemplates.viewTemplate.getLink({
                templateId: siteTemplate.template_id ?? '',
              })
            );
          },
          icon: <EyeOn />,
          label: t('view_site_template'),
        },
        {
          onClick: siteTemplate => setTemplateToDelete(siteTemplate),
          icon: <DeleteDustbin />,
          label: t('delete_template'),
          color: 'reds.500',
        },
      ]
    : [];

  const handleConfirmDelete = async () => {
    if (!templateToDelete || !templateToDelete.template_id) {
      throw new Error('User ID is missing');
    }

    await deleteTemplate(templateToDelete.template_id ?? '', {
      onSuccess: () => {
        enqueueSnackbar(t('site_template_removed_successfully'), {
          key: 'RemoveSiteTemplate',
          variant: 'success',
        });
        setTemplateToDelete(null);
      },
    });
  };

  return (
    <>
      {createSiteFromTemplate ? (
        <CreateSiteModal
          siteTemplateId={createSiteFromTemplate}
          onCancel={() => setCreateSiteFromTemplate(null)}
        />
      ) : null}
      {templateToDelete ? (
        <ConfirmationDialog
          title={t('site_template_delete')}
          description={
            <Trans
              i18nKey="delete_description"
              values={{ name: templateToDelete.name ?? templateToDelete.template_id }}
            />
          }
          confirmColor="error"
          onClose={() => setTemplateToDelete(null)}
          onConfirm={handleConfirmDelete}
          confirmText={t('delete')}
        />
      ) : null}
      <Table
        title={
          <Stack direction="row" alignItems="center">
            {t('site_templates')}
          </Stack>
        }
        label={t('site_templates')}
        rowActions={rowActions}
        columns={columns}
        data={data?.data.result?.site_templates ?? []}
        totalRowCount={data?.data.metadata?.total ?? 0}
        isLoading={isLoading}
        state={tableState}
        enablePagination
        enableSearch={false}
        emptyState={
          <EmptyState
            icon={<Monitor />}
            title={t('no_site_templates')}
            description={t('no_site_templates_description')}
            action={
              <RoleGuard roles={['billing_admin']} type="block">
                <CreateSiteTemplate />
              </RoleGuard>
            }
          />
        }
        rowIdKey="template_id"
      >
        <RoleGuard roles={['billing_admin']} type="block">
          <CreateSiteTemplate />
        </RoleGuard>
      </Table>
    </>
  );
}
